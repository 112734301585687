<template>
  <div
    class="review"
    :class="{ public: type === 'public', personal: type === 'personal' }"
  >
    <div class="review__content">
      <div class="review__info">
        <div class="review__status" v-if="type === 'personal'">
          {{ $t(status) }}
        </div>
        <div class="review__user" v-if="type === 'public'">
          {{ review.name }}
        </div>
        <div class="review__product" v-if="type === 'personal'">
          {{ review.product_title }}
        </div>
        <div class="review__rating">
          <StarRating :grade="review.rating"></StarRating>
          <span v-if="type === 'public'"> {{ rating }}</span>
        </div>
        <div class="review__date">{{ formatDate }}</div>
      </div>
      <div class="review__body">
        {{ $t("labels.comments") }}:
        <span v-if="type === 'public'" v-html="review.review"></span>
        <input
          type="text"
          ref="comment"
          v-if="type === 'personal'"
          v-model="comment"
          :readonly="readonly"
        />
      </div>
    </div>
    <!-- <div class="review__btns">
      <button class="review__btn main-btn" v-if="!readonly" @click="editReview">
        Сохранить
      </button>
      <button class="review__btn main-btn" @click="getAccess">
        <span v-if="readonly">Редактировать</span>
        <span v-else>Отменить</span>
      </button>
      <button class="review__btn second-btn" @click="deleteReview">
        Удалить
      </button>
    </div> -->
  </div>
</template>

<script>
// import StarRating from "@/components/StarRating";
import axios from "axios";
import StarRating from "@/components/StarRating";
import { mapActions } from "vuex";

export default {
  components: { StarRating },
  props: {
    review: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      readonly: true,
      comment: "",
    };
  },
  computed: {
    rating() {
      return this.review.rating + ".0";
    },
    formatDate() {
      return new Date(this.review.created_at).toLocaleDateString("ru-RU", {
        year: "numeric",
        day: "numeric",
        month: "numeric",
      });
    },
    status() {
      if (this.review.status === "in_process") return "reviews.status1";
      else return "reviews.status2";
    },
  },
  methods: {
    ...mapActions(["requestUser"]),
    deleteReview() {
      axios
        .delete("/V1/delete-review", {
          params: {
            review_id: this.review.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.requestUser();
        })
        .catch((errors) => {
        });
    },
    editReview() {
      axios
        .put(
          "/V1/edit-review",
          {
            review_id: this.review.id,
            comment: this.comment,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((response) => {
          this.readonly = true;
          this.requestUser();
        })
        .catch((errors) => {
        });
    },
    getAccess() {
      this.readonly = !this.readonly;
      if (this.readonly) {
        this.comment = this.review.comment;
      }
    },
  },
  created() {
    this.comment = this.review.comment;
  },
  watch: {
    readonly: function () {
      if (!this.readonly) {
        this.$refs.comment.focus();
        this.$emit("change", this.comment);
      }
    },
    "reviews.comment": function () {
      this.comment = this.reviews.comment;
    },
  },
};
</script>

<style lang="scss">
.review {
  &__info {
    min-width: 200px;
  }
  &.public {
    @media (min-width: $mobile + px) {
      gap: 0 100px;
      display: flex;
      align-items: center;
    }

    &__info {
      @media (max-width: $mobile + px) {
        margin: 0 0 20px 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    @media (max-width: $mobile + px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  &.personal {
    .review__date {
      color: #9f9f9f;
      margin: 0 0 7px 0;
    }

    .review__rating {
      margin: 0 0 7px 0;
    }
  }

  &__status {
    font-weight: 600;
    font-size: 14px;
    line-height: 142%;
    letter-spacing: 0.02em;
    color: #9f9f9f;
    margin: 0 0 18px 0;
  }

  &__product {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 7px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 10px 0;
    }
  }

  &__user {
    font-weight: 600;
    font-size: 16px;
    line-height: 142%;
    letter-spacing: 0.02em;
    @media (min-width: $mobile + px) {
      margin: 0 0 10px 0;
    }
    @media (max-width: $mobile + px) {
      flex: 1 1 100%;
    }
  }

  &__rating {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0 10px;
    @media (min-width: $mobile + px) {
      margin: 0 0 10px 0;
    }
  }

  &__date {
    font-weight: 600;
    font-size: 14px;
    line-height: 142%;
    letter-spacing: 0.02em;
  }

  &__body {
    font-weight: 600;
    font-size: 16px;
    line-height: 142%;
    letter-spacing: 0.02em;
    color: #202020;

    span {
      font-weight: 400;
    }
  }

  &__btns {
    display: flex;
    gap: 20px 17px;
    flex-wrap: wrap;
    align-items: center;
    margin: 25px 0 0 0;
    @media (max-width: $mobileSmall + px) {
      margin: 29px 0 0 0;
    }
  }

  &__btn {
    padding: 0 40px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }
}
</style>